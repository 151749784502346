<template>
  <section id="testimonial">
    <picture class="testimonial__curves">
      <source
        :srcset="
          require(`@/assets/images/home/testimonials/testimonial-start-web.webp`)
        "
        type="image/webp"
      />
      <source
        :srcset="
          require(`@/assets/images/home/testimonials/testimonial-start-web.png`)
        "
        type="image/png"
      />
      <img
        :src="
          require(`@/assets/images/home/testimonials/testimonial-start-web.png`)
        "
        alt="testimonial__curves"
      />
    </picture>
    <section class="testimonial">
      <section class="testimonial__items">
        
    <h1 class="section-block__title section-block__title--white">TESTIMONIALS</h1>
        <div class="testimonial__content">
          <div class="testimonial__circles">
            <Testimonial
              :testimonial="testimonials[0]"
              class="testimonial__circle"
            />
            <Testimonial
              :testimonial="testimonials[1]"
              class="testimonial__circle"
            />
          </div>
          <div class="testimonial__circles">
            <Testimonial
              :testimonial="testimonials[2]"
              class="testimonial__circle2"
            />
            <picture class="testimonial__img__container">
              <source
                :srcset="
                  require(`@/assets/images/home/testimonials/review-image.webp`)
                "
                type="image/webp"
              />
              <source
                :srcset="
                  require(`@/assets/images/home/testimonials/review-image.png`)
                "
                type="image/png"
              />
              <img
                class="testimonial__img"
                :src="
                  require(`@/assets/images/home/testimonials/review-image.png`)
                "
                alt="testimonial__img"
                width="456px"
                height="305px"
              />
            </picture>
            <Testimonial
              :testimonial="testimonials[3]"
              class="testimonial__circle2"
            />
            <picture class="testimonial__img__container2">
              <source
                :srcset="
                  require(`@/assets/images/home/testimonials/review-image.webp`)
                "
                type="image/webp"
              />
              <source
                :srcset="
                  require(`@/assets/images/home/testimonials/review-image.png`)
                "
                type="image/png"
              />
              <img
                class="testimonial__img"
                :src="
                  require(`@/assets/images/home/testimonials/review-image.png`)
                "
                alt="testimonial__img"
              />
            </picture>
          </div>
        </div>
      </section>
      <div style="clear: both"></div>
    </section>
    <picture class="testimonial__curves">
      <source
        :srcset="
          require(`@/assets/images/home/testimonials/testimonial-end-web.webp`)
        "
        type="image/webp"
      />
      <source
        :srcset="
          require(`@/assets/images/home/testimonials/testimonial-end-web.png`)
        "
        type="image/png"
      />
      <img
        :src="
          require(`@/assets/images/home/testimonials/testimonial-end-web.png`)
        "
        alt="testimonial__curves"
      />
    </picture>
  </section>
</template>

<script>
import Testimonial from "@/components/home/testimonial/TestimonialItem.vue";
export default {
  data() {
    return {
      testimonials: [
        {
          id: 1,
          title: "Website Development",
          description:
            "Appreciated the team's effort, creativity, patience and can-do attitude during the process of creating Alpha Global website! It was a pleasant working experience with the team.",
          company: "Alpha Global Wellness",
        },
        {
          id: 2,
          title: "Hosting & Deployment",
          description:
            "Mr. Jowett displayed an enthusiastic and passionate demeanour and was open to our requests and proposals. He was patient throughout the discussions that we had and ensured that our requests and criteria are met.",
          company: "HUG Community Services Limited",
        },
        {
          id: 3,
          title: "Portfolio Website",
          description:
            "Working with WebBricksSG team was a pleasant experience. Displayed praiseworthy transparency and development skills. Looking forward to next project. ",
          company: "Syahmi Abbas",
        },
        {
          id: 4,
          title: "IT Consulting Services",
          description:
            "WebBricksSG, being our IT Consultant, is professional and unmediated. We would like to commend Mr. Jowett for his patience and genuineness throughout our course of discussions.",
          company: "HuatWealth88",
        },
      ],
    };
  },
  name: "testimonial",
  components: {
    Testimonial,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.testimonial__curves,
.testimonial__curves img {
  width: 100%;
}
.testimonial {
  width: 100%;
  background-color: #37484f;
  margin-top: -5px;
}

.testimonial__items {
  margin-left: 10vw;
  margin-right: 10vw;
}

.testimonial__circles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.testimonial__circle:nth-child(1) {
  margin-top: -80px;
  margin-left: 100px;
}
.testimonial__circle:nth-child(2) {
  margin-left: -300px;
  margin-top: -10px;
}

.testimonial__circle2:nth-child(1) {
  margin-top: -115px;
}

.testimonial__img__container {
  width: 30%;
  margin: auto;
}
.testimonial__img__container2 {
  display: none;
}

.testimonial__img {
  width: 100%;
  height: auto;
}

.solution__title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.solution__title {
  font-size: 35px;
  font-weight: 700;
}

.solution__content {
  margin-left: 10vw;
  margin-right: 10vw;
}

@media only screen and (max-width: 1370px) {
  .testimonial__circle:nth-child(2) {
    margin-left: -250px;
  }
}
@media only screen and (max-width: 1250px) {
  .testimonial__circle:nth-child(2) {
    margin-left: -120px;
  }
}
@media only screen and (max-width: 1120px) {
  .testimonial__circle:nth-child(1),
  .testimonial__circle:nth-child(2),
  .testimonial__circle2:nth-child(1),
  .testimonial__circle2:nth-child(2),
  .testimonial__circle2:nth-child(3) {
    margin-top: 50px;
    margin-left: 0px;
  }

  .testimonial__img__container {
    display: none;
  }

  .testimonial__img__container2 {
    display: block;
  }

  .testimonial__img {
    width: 60%;
    float: right;
    margin-right: -6vw;
    margin-top: 50px;
  }
}
</style>
