<template>
  <section class="section-block">
    <h1 class="section-block__title">CONTACT US</h1>
    <div class="contact-container">
      <contact-form />
      <picture>
        <source
          :srcset="require(`@/assets/images/contactus/questions.webp`)"
          type="image/webp"
        />
        <source
          :srcset="require(`@/assets/images/contactus/questions.png`)"
          type="image/png"
        />
        <img
          :src="require(`@/assets/images/contactus/questions.png`)"
          alt="question image"
          class="contact-container__img"
          width="591px"
          height="361px"
        />
      </picture>
    </div>
  </section>
</template>

<script>
import ContactForm from "@/components/home/contact/ContactForm.vue";
export default {
  components: {
    ContactForm,
  },
};
</script>

<style lang="scss" scoped>
.contact-container {
  margin: 5% 10%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &__img {
    max-height: 463px;
    // width: 100%;

    @media only screen and (max-width: 1120px) {
      display: none;
    }
  }
  @media only screen and (max-width: 1367px) {
    flex-direction: column;
    flex-flow: column-reverse;
  }
}
</style>
