<template>
  <div id="app">
    <nav-bar />
    <router-view />
    <custom-footer/>
  </div>
</template>
<script>
import NavBar from "./components/NavBar.vue";
import CustomFooter from "@/components/footer/Footer";
export default {
  components: {
    NavBar,
    CustomFooter
  },
  metaInfo: {
    title: "webBricks",
    titleTemplate: "%s | Building Connections & Engagements, From concept to reality, WebBricks has you covered brick by brick",
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  min-height:100vh;
}
body {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
}

#sectionTitles {
  font-family: "Raleway", sans-serif;
  font-weight: 100;
  font-size: 14px;
}
</style>
