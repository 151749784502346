<template>
<div class ="gradButton__container">
<div class = "gradButton">
  <router-link :to="link" class="hyper-link">
    <span class ="gradButton_title">{{ title }}</span>
  </router-link>
</div>
</div>
</template>

<script>
export default {
  props: {
    title: String,
    link: String,
  },
};
</script>

<style scoped>
.gradButton__container{
  width:110px;
}

.hyper-link{
	text-decoration: none;
    color:white;
}
.gradButton{
    text-align:center;
    font-size:10px;
    color:white !important;
    text-decoration: none !important;
    padding:13px;
    margin:auto;
    background-color:#00BBAE;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}
@media only screen and (min-width: 1024px) {
  .gradButton{
    font-size:13px;
  }
}


</style>