<template>
  <section class="project">
    <div class="project__content">
    <h1 class="section-block__title">PROJECTS</h1>
      <div class="project__row" >
        <div class="project__picture-container left" data-aos = "fade-up">
          <picture>
            <source
              :srcset="
                require(`@/assets/images/project/${projects[0].path}.webp`)
              "
              type="image/webp"
            />
            <source
              :srcset="
                require(`@/assets/images/project/${projects[0].path}.png`)
              "
              type="image/jpeg"
            />
            <img
              class="project__pic"
              :src="require(`@/assets/images/project/${projects[0].path}.png`)"
              alt="project__pic"
              width="911px"
              height="648px"
            />
          </picture>
        </div>
        <div class="project__detail right" data-aos = "fade-down">
          <div class="project__subtitle">Game site</div>
          <div class="project__cilent">National University Hospital (NUH)</div>
          <div class="project__description">
            Front-end &amp; Back-end development
          </div>
          <div class="project__description">Game Web Application (Nurse day 2022)</div>
          <GTMetrixScore :perf="projects[0].score.performance" :struct="projects[0].score.structure"/>
          <div class="project__button-container">
            <green-button
              class="right__button green_button"
              title="VISIT SITE"
              link="/nuhs_dalgona"
            />
          </div>
        </div>
      </div>
      <div class="project__row">
        <div class="project__detail left laptop-view" data-aos = "fade-up">
          <div class="project__subtitle">Web Application</div>
          <div class="project__cilent">Queensway Anytime Fitness</div>
          <div class="project__description">
            Front-end &amp; Back-end development
          </div>
          <div class="project__description"></div>
          <GTMetrixScore :perf="projects[1].score.performance" :struct="projects[1].score.structure" v-if="projects[1].hasScore"/>
          <div class="project__button-container">
            <green-button
              class="left__button green_button"
              title="VISIT SITE"
              link="/covidbot"
            />
          </div>
        </div>
        <div class="project__picture-container right" data-aos = "fade-down">
          <picture>
            <source
              :srcset="
                require(`@/assets/images/project/${projects[1].path}.webp`)
              "
              type="image/webp"
            />
            <source
              :srcset="
                require(`@/assets/images/project/${projects[1].path}.png`)
              "
              type="image/jpeg"
            />
            <img
              class="project__pic"
              :src="require(`@/assets/images/project/${projects[1].path}.png`)"
              alt="project__pic"
              width="911px"
              height="648px"
            />
          </picture>
        </div>
        <div class="project__detail left mobile-view" data-aos = "fade-up">
          <div class="project__subtitle">Web Application</div>
          <div class="project__cilent">Queensway Anytime Fitness</div>
          <div class="project__description">
            Front-end &amp; Back-end development
          </div>
          <div class="project__description"></div>
          <GTMetrixScore :perf="projects[1].score.performance" :struct="projects[1].score.structure" v-if="projects[1].hasScore"/>
          <div class="project__button-container">
            <green-button
              class="left__button green_button"
              title="VISIT SITE"
              link="/"
            />
          </div>
        </div>
      </div>
      <green-button
        class="green_button middle"
        title="VIEW ALL"
        link="/project"
      />
    </div>
  </section>
</template>

<script>
import GreenButton from "@/components/GreenButton.vue";
import GTMetrixScore from "@/components/project/GTMetrixScore.vue"
export default {
  data() {
    return {
      projects: [
        {
          id: 1,
          path: "nuhs_dalgona",
          hasScore: true,
           score: {
            performance: 98,
            structure: 100,
          },
        },
        {
          id: 4,
          path: "queenswayAF",
          hasScore: true,
           score: {
            performance: 98,
            structure: 98,
          },
        },
      ],
    };
  },
  name: "project",
  components: {
    GreenButton,
    GTMetrixScore,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.project {
  padding-bottom: 50px;
  width: 100%;
}
.project__pic {
  width: 100% !important;
  height: auto !important;
}
.project__picture-container {
  min-height: 100px;
}

.project__detail {
  text-transform: uppercase;
  text-align: center;
}

.project__content {
  margin-left: 5vw;
  margin-right: 5vw;
}
.project__subtitle {
  color: #3ddbcf;
  font-weight: 700;
  padding-bottom: 8px;
  font-size: 12px;
}
.project__cilent {
  color: #00a99d;
  font-weight: 900;
  font-size: 17px;
  padding-bottom: 8px;
}
.project__description {
  color: #777777;
  font-weight: 900;
  font-size: 11px;
  padding-bottom: 8px;
}
.project__button-container {
  padding: 5px;
}
.project__row {
  vertical-align: middle;
  margin-top: 50px;
  margin-bottom: 50px;
}
.middle {
  margin: auto;
  font-size: 20px !important;
}
@media only screen and (max-width: 1024px) {
  .green_button {
    margin: auto;
  }
  .mobile-view {
    display: block;
  }
  .laptop-view {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .laptop-view {
    display: block;
  }
  .mobile-view {
    display: none !important;
  }
  .green_button {
    margin: none !important;
  }
  .right {
    text-align: right;
  }
  .left {
    text-align: left;
  }
  .right__button {
    margin-left: auto !important;
  }
  .left__button {
    margin-right: auto !important;
  }
  .project__content {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .project__picture-container,
  .project__detail {
    display: inline-block;
    vertical-align: middle;
  }
  .project__detail {
    width: 40%;
  }
  .project__picture-container {
    width: 60%;
  }
  .right {
    text-align: right;
  }
  .project__subtitle {
    font-size: 23px;
  }
  .project__cilent {
    font-size: 28px;
  }
  .project__description {
    font-size: 15px;
  }
}
</style>
