<template>
  <div class="form-container" id="ContactUs">
    <div class="input-container">
      <font-awesome-icon :icon="['fas', 'question-circle']" size="2x" />
      <div class="field">
        <label for="query" class="active">Type of Query</label>
        <select name="query" id="query" v-model="type">
          <option value="Web Solution/App">Website Development</option>
          <option value="Mobile Development">Telegram Bot Development</option>
          <option value="General Enquiry">Custom Web-Based Solution</option>
        </select>
      </div>
    </div>
    <a
      :href="`mailto:jowettchng@webBricksSG.com?subject=${encodeURIComponent(
        type
      )}`"
      target="_blank"
      rel="noopener noreferrer"
      class="button__gradient"
    >
      ENQUIRE
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "Web Solution/App",
    };
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 70%;
  margin-right: 2rem;
  text-align: left;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
}
.input-container {
  display: flex;
  margin: 2rem 0;
  padding: 1.5rem 1rem 0.8rem 1rem;
  border-radius: 20px;
  border: gray solid 1px;

  svg {
    padding: 0px 10px 10px 5px;
    color: #cccccc;
  }

  .field {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0.5rem;
  }

  select {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: white;
    &:focus {
      box-shadow: 0 0 5px rgba(0, 187, 174, 1);
      border: 0 0 5px rgba(0, 187, 174, 1);
    }
  }
  label {
    color: #9c9ca8;
    font-size: 0.8rem;
    font-weight: normal;
    pointer-events: none;
    margin-left: 3px;
    transition: 300ms ease all;
  }
}
.button__gradient {
  color: white !important;
  text-decoration: none !important;
  padding: 1rem 2rem;
  margin: auto;
  background-image: linear-gradient(to right, #0099d4, #00bbae);
  border-radius: 50px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: inline-block;
    padding: 1rem 0;
    text-align: center;
  }
}
</style>
