import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/project",
    name: "Project",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Project.vue"),
  },
  {
    path: "/service",
    name: "Service",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Service.vue"),
  },
  {
    path: "/service/:type/:id",
    name: "ServiceDetail",
    component: () =>
    import("../views/ServiceDetail.vue"),
    props: true
  },
  {
    path: '/syahmi',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://syahmiabbas.com/"
    }
  },
  {
    path: '/alpgw',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "http://alpgw.com"
    }
  },
  {
    path: '/covidbot',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://t.me/SGCovid19_bot"
    }
  },
  {
    path: '/hug',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://hug.sg"
    }
  },
  {
    path: '/aboutusPost',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://www.facebook.com/oursingaporearmy/posts/10158084620591063"
    }
  },
  {
    path: '/queenswayaf',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://queenswayaf.web.app/"
    }
  },
  {
    path: '/nuhs_dalgona',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://nursev2.vercel.app/dalgona"
    }
  },
  {
    path: '/nuhs_balloon',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://nursev2.vercel.app/balloon"
    }
  },
  {
    path: '/nuhs_wordle',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://nursev2.vercel.app/wordle"
    }
  },
  {
    path: '/moonshot',
    beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://moonshot-webbrickssg.vercel.app/"
    }
  },
  {
    path: '/namecard/jowett',
    component: () =>
      import(/* webpackChunkName: "namecard" */ "../views/namecard/Jowett.vue"),
  },
  {
    path: '/namecard/jayden',
    component: () =>
      import(/* webpackChunkName: "namecard" */ "../views/namecard/Jayden.vue"),
  },
  {
    path: '/namecard/syahmi',
    component: () =>
      import(/* webpackChunkName: "namecard" */ "../views/namecard/Syahmi.vue"),
  },
  {
    path: '/namecard/gerard',
    component: () =>
      import(/* webpackChunkName: "namecard" */ "../views/namecard/Gerard.vue"),
  },
  {
    path: '/namecard/syongyue',
    component: () =>
      import(/* webpackChunkName: "namecard" */ "../views/namecard/SyongYue.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: function(to) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
