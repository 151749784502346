<template>
  <footer class="footer" id="">
    <picture class="footer__curve">
      <source
        media="(max-width: 500px)"
        :srcset="require(`@/assets/images/footer/footer-mobile.webp`)"
        type="image/webp"
      />
      <source
        media="(max-width: 500px)"
        :srcset="require(`@/assets/images/footer/footer-mobile.png`)"
        type="image/png"
      />
      <source
        :srcset="require(`@/assets/images/footer/footer-web.webp`)"
        type="image/webp"
      />
      <source
        :srcset="require(`@/assets/images/footer/footer-web.png`)"
        type="image/png"
      />
      <img
        :src="require(`@/assets/images/footer/footer-web.png`)"
        alt="Footer curve"
      />
    </picture>
    <div class="footer__information">
      <div class="logo-socials">
        <picture class="logo-socials__company">
          <source
            :srcset="require(`@/assets/images/footer/webBricks-logo-dark.webp`)"
            type="image/webp"
          />
          <source
            :srcset="require(`@/assets/images/footer/webBricks-logo-dark.png`)"
            type="image/png"
          />
          <img
            :src="require(`@/assets/images/footer/webBricks-logo-dark.png`)"
            alt="webBricks Logo"
            width="392px"
            height="84px"
          />
        </picture>
        <div class="logo-socials__socials">
          <a
            href="https://www.instagram.com/webBrickssg/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" size="2x" />
            <span>Instagram</span>
          </a>
          <a
            href="https://www.facebook.com/WebBrickSG"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x" />
            <span>Facebook</span>
          </a>
          <a
            href="https://www.linkedin.com/company/66731057"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
            <span>LinkedIn</span>
          </a>
          <a
            href="https://www.twitter.com/"
            target="_blank"
            rel="noopener noreferrer"
            alt="twitter"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" size="2x" />
            <span>Twitter</span>
          </a>
        </div>
      </div>
      <footer-links />
    </div>
    <div class="footer__copyright">
      <span><b> Copyright 2020 - WebBrickSG Solutions PTE LTD </b></span>
    </div>
  </footer>
</template>

<script>
import FooterLinks from "@/components/footer/FooterLinks";
export default {
  components: {
    FooterLinks,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding-top: 5rem;
  &__curve img {
    width: 100%;
    margin-bottom: -5px;
  }

  &__information {
    color: #fff;
    background-color: #37484f;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 2rem;

    &__copyright {
      background-color: #37484f;
      color: #fff;
      padding-bottom: 2rem;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      // padding-bottom: 2rem;
    }
  }
}
.footer__copyright {
  background-color: #37484f;
  color: #fff;
  padding-bottom: 2rem;
}

.logo-socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 392px;
  &__company {
    img {
      width: 100%;
      height: auto !important;

      @media only screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }
  &__socials {
    width: 70%;
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;

    span {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      border: 0;
    }

    svg {
      color: #fff;
    }
  }
}
</style>
