<template>
  <div class="footer-links">
    <div class="footer-links__list">
      <p class="footer-links__header">Links</p>
      <router-link to="/#hero" class="footer-links__link">Home</router-link>
      <router-link to="/#project" class="footer-links__link">
        Projects
      </router-link>
      <router-link to="/#testimonial" class="footer-links__link">
        Testimonials
      </router-link>
      <router-link to="/about" class="footer-links__link">
        About Us
      </router-link>
      <router-link to="/service" class="footer-links__link">
        Services
      </router-link>
    </div>
    <div class="footer-links__list">
      <p class="footer-links__header">Support</p>
      <router-link to="/#project" class="footer-links__link">
        Terms of Use
      </router-link>
      <router-link to="/#testimonial" class="footer-links__link">
        Privacy Policy
      </router-link>
    </div>
    <div class="footer-links__list footer-links--contact">
      <p class="footer-links__header">Contact Us</p>
      <a href="mailto:enquiries@webBricksSG.com" class="footer-links__link">
        Jowettchng@webBricksSG.com
      </a>
      <!-- <a href="tel:+6561234567" class="footer-links__link">+65 6123 4567</a> -->
      <!-- <p class="footer-links__text">Available 9am to 5pm (GMT +8:00)</p> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer-links {
  display: flex;
  justify-content: space-evenly;
  width: 60%;

  @media only screen and (max-width: 768px) {
    width: 90%;
    flex-direction: column;
  }

  &__list {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 1rem;

    @media only screen and (max-width: 768px) {
      padding-top: 1rem;
      align-items: center;
    }
  }

  &__header {
    padding-bottom: 1.5rem;
    color: white;
    font-weight: bold;

    @media only screen and (max-width: 768px) {
      padding-bottom: 0.5rem;
    }
  }
  &__link {
    color: #fff;
    width: fit-content;
    padding-bottom: 1rem;
    margin: 0;
    text-decoration: none;
    transition: 300ms all ease-out;

    @media only screen and (max-width: 768px) {
      padding-bottom: 0.5rem;
    }

    &::after {
      display: block;
      content: "";
      border-bottom: solid 1px white;
      transform: scaleX(0);
      transition: transform 300ms ease-in-out;
    }
    &:visited {
      color: #fff;
    }
    &:hover {
      text-shadow: 0 0 0.9px white, 0 0 0.9px white, 0 0 0.9px white;
      &::after {
        transform: scaleX(1);
      }
    }
  }

  &__text {
    margin: 0;
  }

  &--contact {
    color: #3ddbcf;
    a {
      color: #3ddbcf !important;
      cursor: pointer;

      &::after {
        border-bottom: solid 1px #3ddbcf;
      }
      &:hover {
        text-shadow: 0 0 0.9px #3ddbcf, 0 0 0.9px #3ddbcf, 0 0 0.9px #3ddbcf;
      }
    }
  }
}
</style>
